// **********        Stylesheets
import './Content/vendor/animate.min.css';
import 'toastr/build/toastr.min.css';
import 'perfect-scrollbar/dist/css/perfect-scrollbar.min.css';
import 'angular-material/angular-material.min.css';
import 'ng-material-datetimepicker/dist/material-datetimepicker.min.css';
import '../node_modules/angular/angular-csp.css';
import 'leaflet/dist/leaflet.css';

const w: any = window;

// **********        IE11 polyfills
import 'promise-polyfill/dist/polyfill.min.js';
import 'url-polyfill/url-polyfill.min.js';

// **********........Bugsnag
import bugsnag from 'bugsnag-js';
w.bugsnag = bugsnag;

// **********........Chart.js
import chartjs from 'chart.js';
w.chartjs = chartjs;

// **********        jQuery
import * as jQuery from 'jquery';
w.$ = w.jQuery = jQuery;

import 'signalr/jquery.signalR.min.js';

// **********        Import url and ansi-html to fix runtime errors because of version conflicts in dependencies
import 'url/url.js';
import 'ansi-html/index.js';

import 'immutable/dist/immutable.min.js';
import 'rx-lite/rx.lite.min.js';
import 'imports-loader?define=>false!rx-lite-experimental/rx.lite.experimental.min.js';

// **********        TweenMax
import 'jquery.easing/jquery.easing.min.js';
import 'gsap/src/minified/TweenMax.min.js';
//import './Scripts/Vendor/greensock/src/minified/utils/Draggable.min.js';
//import './Scripts/Vendor/greensock/src/minified/plugins/DrawSVGPlugin.min.js';
//import './Scripts/Vendor/greensock/src/minified/plugins/ScrollToPlugin.min.js';
//import './Scripts/Vendor/greensock/src/minified/plugins/ThrowPropsPlugin.min.js';

// **********        SignaturePad
import * as signaturePad from 'signature_pad/dist/signature_pad.umd.js';
w.SignaturePad = signaturePad;

// **********        CurrentDevice
import * as currentDevice from './Scripts/Javascript/current_device/index.js';
w.CurrentDevice = currentDevice;

// **********        OpenStreetMap
import 'leaflet/dist/leaflet.js';

// **********        Extra
import 'moment/moment'; //ng-material-datetimepicker dependency
import 'datejs_original/date.js';
import 'notify.js/notify.js';
import * as visibility from 'visibilityjs/lib/visibility.core.js';
w.Visibility = visibility;
import * as toastr from 'toastr/build/toastr.min.js';
w.toastr = toastr;
import 'perfect-scrollbar/dist/js/perfect-scrollbar.jquery.min.js';

// **********        Angular (official)
import 'angular';
import 'angular-animate/angular-animate.min.js';
import 'angular-cookies/angular-cookies.min.js';
import 'angular-sanitize/angular-sanitize.min.js';
import 'angular-aria/angular-aria.min.js';
import 'angular-resource/angular-resource.min.js';
import 'angular-material/angular-material.min.js';
import './Scripts/Javascript/angular-perfect-scrollbar/angular-perfect-scrollbar.js';
import './Scripts/Vendor/draggable/src/draggable.js';

// *********         Angular (unofficial)
import 'ngUpload/ng-upload.min.js';
import 'ng-resize/dist/ng-resize.min.js';
import 'angular-webstorage/angular-webstorage.min.js';
import 'angular-click-outside/clickoutside.directive.js';
import 'angular-validator/dist/angular-validator.min.js';
import 'angular-validator/dist/angular-validator-rules.min.js';
import 'angular-uuid2/dist/angular-uuid2.min.js';
import 'ng-material-datetimepicker/dist/angular-material-datetimepicker.min.js';
import 'angular-ui-router/release/angular-ui-router.min.js';
import 'angular-ui-router/release/stateEvents.min.js';
import 'ng-file-upload/dist/ng-file-upload.min.js';

// **********        i18n
import 'angular-i18n/angular-locale_nl-nl.js';
